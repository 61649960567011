import {
	AlaresEntity,
	BusinessEntity,
	CustomerEntity,
	GroupEntity,
	IGroupEntityAttributes,
} from 'Models/Entities';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import {
	DefaultCustomerListPageConfig,
	riskScoreList,
} from 'Views/Pages/Monitor/useCustomerListPageConfiguration/useCustomerListPageConfiguration';
import EntityList, {
	IEntityListHeaderProps,
} from 'Views/Components/EntityList/EntityList';
import {
	HasCondition,
	IWhereCondition,
} from 'Views/Components/ModelCollection/ModelQuery';
import React, { useCallback, useState } from 'react';
import RequestWrap, {
	GetRefreshKey,
} from 'Views/Components/RequestWrap/RequestWrap';
import {
	atoRecordStatus,
	ausStateComboboxOptions,
	ausStates,
	riskScore,
	riskScoreComboboxOptions,
	riskScoreEnumToValueRange,
} from 'Models/Enums';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { ComboboxOption } from '../../Combobox/Combobox';
import ComboboxSetter from 'Views/Components/Combobox/ComboboxSetter';
import CustomerColumnTypes from 'Views/Components/Intel/Customer/CustomerColumnTypes';
import If from 'Views/Components/If/If';
import { Link } from 'react-router-dom';
import { LocaleCompareAttrAsc } from 'Util/StringUtils';
import MultiComboboxSetter from 'Views/Components/Combobox/MultiComboboxSetter';
import { SessionKeys } from 'Constants';
import TablePagination from 'Views/Components/Pagination/TablePagination';
import { TextFieldSetter } from 'Views/Components/TextBox/TextFieldSetter';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import { buildBlob } from 'Util/CsvUtils';
import { buildSearchConditions } from 'Util/GraphQLUtils';
import classNames from 'classnames';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { gql } from '@apollo/client';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { store } from 'Models/Store';
import { CreateAtoDefaultsExportDataForCsv } from './AtoDefaults/ExportAtoDefaults';

export function getAtoDefaultGqlSnippet(recordStatus?: atoRecordStatus[]) {
	const recordStatuses = recordStatus ?? ['N', 'U', 'X', 'R'];
	const minDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
	const maxDate = moment().format('YYYY-MM-DD');
	return `
		monitoredss {
			monitoreds {
				id
				atoRecordss (
				orderBy: [{path: "atoFile.fileDate", descending: true}]
				take: 1
				where: [
					{path: "atoFile.fileDate", comparison: between, value: ["${minDate}", "${maxDate}"]},
					{path: "recordStatus", comparison: in, value: ${JSON.stringify(recordStatuses)}},
					{path: "atoFile.atoProcessingStatus", comparison: equal, case: INVARIANT_CULTURE_IGNORE_CASE, value: "processed"}]
				) {
					id
					debtAmount
					recordStatus
					recordAdded
					recordUpdated
					atoFile {
						id
						atoProcessingStatus
						fileDate
					}
				}
				atoDebt {
					id
					amount
				}
			}
		}`;
}

function UpdateColumns(columns: CustomerColumnInfo[]) {
	const debtBucketColumns = [
		{ fieldName: 'dp1to30', displayName: CustomerColumnTypes.dp1to30.defaultDisplayName },
		{ fieldName: 'dp31to60', displayName: CustomerColumnTypes.dp31to60.defaultDisplayName },
		{ fieldName: 'dp61to90', displayName: CustomerColumnTypes.dp61to90.defaultDisplayName },
		{ fieldName: 'dp91to120', displayName: CustomerColumnTypes.dp91to120.defaultDisplayName },
		{ fieldName: 'dp120plus', displayName: CustomerColumnTypes.dp120plus.defaultDisplayName },
	];

	const existingFields = new Set(columns.map(column => column.fieldName));

	const updatedColumns = [...columns];

	// If the total debt column is present, add the debt bucket columns
	// Only add the debt bucket columns if they are not already present
	if (existingFields.has('totalDebt')) {
		debtBucketColumns.forEach(column => {
			if (!existingFields.has(column.fieldName)) {
				updatedColumns.push(column);
			}
		});
	}

	return updatedColumns;
}

// Method to fetch and return the CustomerEntitys based on the page number and sorting
const fetchCustomers = async (
	columns: CustomerColumnInfo[],
	search: string,
	sortParams: SortParams,
	filterParams: FilterParams,
	page: number,
	setCount: (newCount: number) => void,
	noPagination?: boolean,
) => {
	// Update columns to calculate the exact value for total Debt.
	const updateColumns = UpdateColumns(columns);

	// If there is a search included, create the search param for the query
	const comparison = 'INVARIANT_CULTURE_IGNORE_CASE';
	let searchConditions: IWhereCondition<string>[] = [];
	if (!!search) {
		searchConditions = buildSearchConditions(['businessName', 'abn', 'debtorID', 'acn', 'trusteeACN'], search, comparison);
	}

	const conditions: IWhereCondition<string>[][] = [];
	const conditionsOr: IWhereCondition<string>[][] = [];
	const hasConditions: HasCondition<CustomerEntity>[][] = [];

	// Aggregate conditions from search and filters into single nested list
	if (searchConditions.length > 0) {
		conditions.push(searchConditions);
	}

	// Setup ATO defaults conditions filter
	let atoDefaultGqlSnippet = getAtoDefaultGqlSnippet();
	if (filterParams.atoDefaults === 'YES') {
		// add conditions for ato debt
		hasConditions.push([
			{
				path: 'monitoredss',
				conditions: [
					[
						{
							path: 'monitoreds.atoDebt.amount',
							comparison: 'greaterThanOrEqual',
							value: '0',
						},
					],
					[
						{
							path: 'monitoreds.atoRecordss.count',
							comparison: 'greaterThan',
							value: '0',
						},
					],
				],
			},
		]);

		// get all the possible ato records for the last 2 weeks
		// and the ato file status is processed
		atoDefaultGqlSnippet = getAtoDefaultGqlSnippet();
	}

	if (filterParams.auState !== 'ALL') {
		conditions.push([{
			comparison: 'contains', path: 'locality', value: filterParams.auState,
		}]);
	}

	// Setup payment risk conditions filter
	if (filterParams.paymentRiskRating !== 'ALL') {
		conditions.push([{
			comparison: 'equal', path: 'customPaymentRiskRating', value: filterParams.paymentRiskRating,
		}]);
	}

	// Setup insolvency risk conditions filter
	if (filterParams.insolvencyRiskRating !== 'ALL') {
		if (filterParams.insolvencyRiskRating === 'UNKNOWN') {
			conditions.push([{
				comparison: 'equal',
				path: 'insolvencyRiskRating',
				value: ['-1'],
			}]);
		} else {
			conditions.push([{
				comparison: 'between',
				path: 'insolvencyRiskRating',
				value: riskScoreEnumToValueRange[filterParams.insolvencyRiskRating],
			}]);
		}
	}

	// Setup riskBucket conditions filter
	if (filterParams.riskBucket?.length && !filterParams.riskBucket.some(r => r === 'ALL')) {
		const { riskBucket } = filterParams;

		const riskBucketIndexFirst = riskScoreList.indexOf(riskBucket[0]);
		const riskBucketIndexSecond = riskScoreList.indexOf(riskBucket[riskBucket.length - 1]);
		const riskBucketMinIndex = Math.min(riskBucketIndexFirst, riskBucketIndexSecond);
		const riskBucketMaxIndex = Math.max(riskBucketIndexFirst, riskBucketIndexSecond);

		const atRiskBucketMin = riskScoreList[riskBucketMinIndex];
		const atRiskBucketMax = riskScoreList[riskBucketMaxIndex];

		const riskScoreInsolvencyValueMax = atRiskBucketMax in riskScoreEnumToValueRange
			? riskScoreEnumToValueRange[atRiskBucketMax][1]
			: '0';
		const riskScoreInsolvencyValueMin = atRiskBucketMin in riskScoreEnumToValueRange
			? riskScoreEnumToValueRange[atRiskBucketMin][0]
			: '-1';

		const riskBucketsBetweenMinAndMaxRiskBuckets = riskScoreList
			.filter((value, index) => index >= riskBucketMinIndex && index <= riskBucketMaxIndex);
		const riskScoresIncludingOrBelowMaxRiskBucket = riskScoreList
			.filter((value, index) => index <= riskBucketMaxIndex);

		conditionsOr.push(
			[
				{
					path: 'customPaymentRiskRating',
					value: riskBucketsBetweenMinAndMaxRiskBuckets,
					comparison: 'in',
				},
				{
					path: 'insolvencyRiskRating',
					value: riskScoreInsolvencyValueMax,
					comparison: 'lessThan',
				},
			],
			[
				{
					path: 'insolvencyRiskRating',
					value: [
						riskScoreInsolvencyValueMin,
						riskScoreInsolvencyValueMax,
					],
					comparison: 'between',
				},
				{
					path: 'customPaymentRiskRating',
					value: riskScoresIncludingOrBelowMaxRiskBucket,
					comparison: 'in',
				},
			],
		);
	}

	// Build business entity conditions
	if (filterParams.businessEntityIds.length > 0) {
		conditions.push([{
			path: 'businessEntityId',
			comparison: 'in',
			value: filterParams.businessEntityIds,
		}]);
	}

	// Build group conditions
	if (filterParams.groupIds.length > 0) {
		hasConditions.push([{
			path: 'groupss',
			conditions: [[{ path: 'groupsId', comparison: 'in', value: filterParams.groupIds }]],
		}]);
	}

	// Add sorting
	const orderBy: { path: string, descending: boolean }[] = [];
	if (!!sortParams?.fieldName && CustomerColumnTypes[sortParams?.fieldName].sortColumn !== undefined) {
		// if we have a space in the sort column we split to sort by multiple columns
		const sortColumns = (CustomerColumnTypes[sortParams?.fieldName].sortColumn ?? '').split(' ');
		orderBy.push(...sortColumns.map(x => {
			return { path: x, descending: sortParams?.direction === 'DESC' };
		}));
	}
	
	const query = gql`
		query customerEntitys(
			$orderBy: [OrderByGraph],
			$take: Int,
			$skip: Int,
			$conditions: [[WhereExpressionGraph]],
			$conditionsOr: [[WhereExpressionGraph]],
			$hasConditions: [[HasConditionType]],
		) {
			customerEntitys(
				conditions: $conditions, 
				conditionsOr: $conditionsOr, 
				has: $hasConditions,
				skip: $skip, 
				take: $take, 
				orderBy: $orderBy
			) {
				id
				acn
				trusteeACN
				monitoredStatus
				${updateColumns.map(x => CustomerColumnTypes[x.fieldName].gqlSnippet).join('\n')}
				${atoDefaultGqlSnippet}
			}
			${
	!noPagination
		? `countCustomerEntitys(conditions: $conditions, conditionsOr: $conditionsOr, has: $hasConditions) {
				number
			}`
		: ''
}
		}
	`;
	const { data } = await store.apolloClient.query({
		query: query,
		fetchPolicy: 'network-only',
		variables: {
			orderBy: orderBy,
			take: !noPagination ? CUSTOMERS_PAGE_LENGTH : null,
			skip: !noPagination ? CUSTOMERS_PAGE_LENGTH * (page || 0) : null,
			conditions: conditions,
			conditionsOr: conditionsOr,
			hasConditions: hasConditions,
		},
	});

	const responseData = data.customerEntitys.map((customer: any) => new CustomerEntity(customer)) as CustomerEntity[];

	// update the adverse alerts count in each customer entity based on the ato records
	responseData.forEach(customer => {
		let adverseAlertCount = customer.newestAlares?.adverseAlerts ?? 0;
		if(customer.monitoredss.length > 0) {
			const monitored = customer.monitoredss.map(m => m.monitoreds).filter(m => !!m);
			if (monitored.length > 0) {
				// add ato debt count to the adverse alert count
				adverseAlertCount += monitored.filter(m => m.atoDebt !== null).length;
			}
		}
		if (customer.newestAlares) {
			customer.newestAlares.adverseAlerts = adverseAlertCount;
		} else if (adverseAlertCount > 0) {
			// create a new alares entity if it doesn't exist
			// and add the adverse alert count to it
			// this is to ensure that the adverse alert count with ato debt count is displayed in the customer list page 
			customer.newestAlares = new AlaresEntity({ adverseAlerts: adverseAlertCount });
		}
	});

	if (!noPagination) {
		setCount(data.countCustomerEntitys.number);
	}

	return responseData.map((customer: any) => new CustomerEntity(customer)) as CustomerEntity[];
};

// method to debounce the fetch customers method
const fetchCustomersDebounced = AwesomeDebouncePromise(async (
	columns: CustomerColumnInfo[],
	search: string,
	sortParams: SortParams,
	filterParams: FilterParams,
	page: number,
	setCount: (newCount: number) => void,
	noPagination?: boolean,
) => {
	return fetchCustomers(
		columns,
		search,
		sortParams,
		filterParams,
		page,
		setCount,
		noPagination,
	);
}, 400, {
	// We use leading, so that one-off changes like sorting columns apply immediately
	// Changing the search text will trigger on the first character, and then again after the final change
	leading: true,
});

export interface CustomerColumnInfo {
	fieldName: string,
	displayName: string,
}

export interface SortParams {
	fieldName: string;
	direction: 'ASC' | 'DESC';
}
export interface FilterParams {
	auState: ausStates | 'ALL';
	businessEntityIds: string[],
	paymentRiskRating: riskScore | 'ALL',
	insolvencyRiskRating: riskScore | 'ALL',
	riskBucket: (riskScore | 'ALL')[],
	groupIds: string[],
	atoDefaults: 'YES' | 'NO'
}

export interface CustomerListProps {
	columns: CustomerColumnInfo[],
	sort: SortParams,
	filter: FilterParams,
}

function onlyDefaultFilterValueExist(filter: FilterParams) {
	return filter.auState === 'ALL'
		&& filter.paymentRiskRating === 'ALL'
		&& filter.insolvencyRiskRating === 'ALL'
		&& (filter.riskBucket.length === 1 && filter.riskBucket[0] === 'ALL')
		&& filter.businessEntityIds.length === 0
		&& filter.groupIds.length === 0
		&& filter.atoDefaults === 'NO';
}

const CustomerList = (props: CustomerListProps) => {
	const {
		columns,
		sort: initSort,
		filter: initFilter,
	} = props;

	const [search, setSearch] = useState('');
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [showFilters, setShowFilters] = useState(() => !onlyDefaultFilterValueExist(initFilter));

	const [sortParams, setSortParams] = useState<SortParams>(() => {
		// Checking the provided sort column is valid
		const initSortFieldName = (initSort?.fieldName
			? columns.find(c => (
				// Check field exists
				c.fieldName === initSort?.fieldName
				// Check field can be sorted
				&& !!CustomerColumnTypes[c.fieldName]?.sortColumn
			))?.fieldName
			: null
		);

		// Use provided sort column if available, otherwise find the first
		// sortable column, falling back to no sort.
		const sortFieldName = (
			initSortFieldName
			?? columns.find(c => !!CustomerColumnTypes[c.fieldName]?.sortColumn)?.fieldName
			?? ''
		);

		return {
			fieldName: sortFieldName,
			direction: initSort?.direction ?? 'ASC',
		};
	});

	const [filterParams, setFilterParams] = useState<FilterParams>(initFilter);
	const [oldRiskBucket, resetOldRiskBucket] = useState<(riskScore | 'ALL')[]>(filterParams.riskBucket);

	const refreshKey = React.useMemo(() => {
		return GetRefreshKey(
			search,
			...Object.values(sortParams),
			...Object.values(filterParams),
			page,
		);
	}, [search, sortParams, filterParams, page]);

	function updateFilterParams(partial: Partial<FilterParams>) {
		setPage(0);
		setFilterParams(prev => {
			const updated = { ...prev, ...partial };
			resetOldRiskBucket([...updated.riskBucket]);
			return updated;
		});
	}

	function onToggleShowFilter() {
		// Deep clone so we don't mutate the default values
		const updatedFilter = JSON.parse(JSON.stringify(DefaultCustomerListPageConfig.filter));
		sessionStorage.removeItem(SessionKeys.customerListPageConfig);
		updateFilterParams(updatedFilter);
		setShowFilters(show => !show);
	}

	/**
	 * Callback for sorting changes
	 * @param fieldName The fieldName of the sorting column
	 */
	const onSortChange = React.useCallback((fieldName: string) => {
		setSortParams(old => {
			let direction = 'ASC';
			if (!!old.fieldName && old.fieldName === fieldName) {
				direction = old.direction === 'ASC'
					? 'DESC'
					: 'ASC';
			}

			return {
				fieldName: fieldName,
				direction: direction,
			} as SortParams;
		});
	}, []);

	function onRiskBucketSetValue(v: (riskScore | 'ALL')[]) {
		if (!v || v.length === 0) {
			updateFilterParams({ riskBucket: ['ALL'] });
			return;
		}

		const addedRiskBucket = v.find(r => oldRiskBucket.indexOf(r) === -1)
			?? oldRiskBucket[1];

		if (addedRiskBucket === 'ALL') {
			updateFilterParams({ riskBucket: ['ALL'] });
			return;
		}
		if (v.length === 1 || v.length === 2) {
			updateFilterParams({ riskBucket: v.filter(r => r !== 'ALL') });
			return;
		}

		const newRiskBucket = [
			oldRiskBucket[1],
			addedRiskBucket,
		];

		// Remove the first element so that the last two values selected
		// will be used as the between.
		updateFilterParams({ riskBucket: newRiskBucket });
	}

	// Method to fetch and return the CustomerEntitys based on the page number and sorting
	const fetchCustomersForExport = async () => {
		const customers: CustomerEntity[] = await fetchCustomers(columns, search, sortParams, filterParams, -1, () => { }, true);
		const result = [] as string[][];

		// If we are exporting ATO defaults, we need to use a different method
		if (filterParams.atoDefaults === 'YES') {
			return CreateAtoDefaultsExportDataForCsv(customers);
		}

		const headers = columns.map(column => column.displayName);
		result.push(headers);
		customers.forEach(customer => {
			result.push(
				columns.map(column => {
					if (!!CustomerColumnTypes[column.fieldName].stringDisplayFunction) {
						// @ts-ignore
						return CustomerColumnTypes[column.fieldName].stringDisplayFunction(customer) ?? '';
					}
					// @ts-ignore
					return CustomerColumnTypes[column.fieldName].displayFunction(customer)?.toString() ?? '';
				}),
			);
		});

		return result;
	};

	// Create callback for fetching group options and set list limit to 50 (should be plenty in most cases, mainly for super)
	const MAX_NUMBER_OF_GROUPS = 50;
	const getGroupOptions = useCallback(async (searchTerm?: string): Promise<ComboboxOption<string>[]> => {
		const groupsResult = await store.apolloClient
			.query({
				query: gql`
							query fetchGroupEntities($searchTerm: String) {
								groupEntitys (
									where: [{
										path: "name",
										comparison: like,
										value: [$searchTerm],
										case: CURRENT_CULTURE_IGNORE_CASE
									}],
									orderBy: [{path: "name", descending: false}],
									take: ${MAX_NUMBER_OF_GROUPS},
								) {
									id
									name
								}
							}
						`,
				variables: {
					searchTerm: !!searchTerm ? `%${searchTerm}%` : '%',
				},
				fetchPolicy: 'no-cache',
			});

		const groups = groupsResult.data.groupEntitys
			.map((x: Partial<IGroupEntityAttributes> | undefined) => new GroupEntity(x));
		return groups.map((x: GroupEntity) => {
			return {
				display: x.name,
				value: x.id,
			};
		});
	}, []);
	function onClickExportCsv() {
		confirmModal(
			'Export CSV',
			'Export your customers and save to a CSV file?',
			{
				cancelText: 'Cancel',
				confirmText: 'Export',
			},
		).then(async () => {
			const exportCustomers = await fetchCustomersForExport();
			const blob = buildBlob(exportCustomers, ',', '"');
			saveAs(blob, `exported-customers-${moment().format('YYYY-MM-DD')}.csv`);
		});
	}
	function onClickCustomerRow(customer: CustomerEntity) {
		sessionStorage.setItem(SessionKeys.customerListPageConfig, JSON.stringify({
			sort: sortParams,
			filter: filterParams,
		}));

		store.routerHistory.push(`/monitor/customers/${customer.id}`);
	}

	function renderExportCsvButton() {
		const displayName = filterParams.atoDefaults === 'YES' ? 'Export ATO Defaults' : 'Export CSV';
		return (
			<Button
				className="export-button"
				colors={Colors.Alternate}
				display={Display.Outline}
				onClick={async () => onClickExportCsv()}
			>
				{displayName}
			</Button>
		);
	}
	return (
		<div className="customer-list">
			<div className="search-container">
				<div className="searchbar search">
					<TextFieldSetter
						className={classNames('search-input search__collection')}
						value={search}
						setValue={setSearch}
						label=""
						onAfterChange={() => setPage(0)}
						labelVisible={false}
						placeholder="Search by Business Name/ABN/ACN/Debtor Id"
					/>

					<Button
						className={classNames('filter-toggle-btn', { active: showFilters })}
						icon={{ icon: 'filter-2', iconPos: 'icon-left' }}
						colors={Colors.Primary}
						display={showFilters ? Display.Solid : Display.Outline}
						onClick={() => onToggleShowFilter()}
					>
						{showFilters ? 'Clear Filter' : 'Filter By'}
					</Button>
				</div>

				<div className="buttons-container">
					{renderExportCsvButton()}
					<Button
						className="add-customer-btn icon-plus icon-left"
						colors={Colors.Primary}
						display={Display.Solid}
						onClick={() => store.routerHistory.push('/monitor/customers/add')}
					>
						Add Customer
					</Button>
				</div>
				<If condition={showFilters}>
					<div className="search-filter-section">
						<ComboboxSetter
							className="state-filter"
							value={filterParams.auState}
							setValue={v => updateFilterParams({ auState: v })}
							getOptionValue={(value?: string) => value}
							placeholder="State"
							label=""
							labelVisible={false}
							searchable
							options={ausStateComboboxOptions}
						/>
						<MultiComboboxSetter
							placeholder="Business entity"
							value={filterParams.businessEntityIds}
							setValue={v => updateFilterParams({ businessEntityIds: v })}
							className="business-entity-filter"
							searchable
							getOptionValue={(value?: string) => value}
							label=""
							labelVisible={false}
							options={store.getUser?.organisation?.businessEntitys
								.filter(BusinessEntity.IsLinkedToUser)
								.sort(LocaleCompareAttrAsc('name'))
								.map(
									x => { return { display: x.name, value: x.id }; },
								) ?? []}
						/>
						<ComboboxSetter
							className="payment-risk-filter"
							value={filterParams.paymentRiskRating}
							setValue={v => updateFilterParams({ paymentRiskRating: v })}
							getOptionValue={(value?: string) => value}
							placeholder="Payment risk rating"
							label=""
							labelVisible={false}
							searchable
							options={riskScoreComboboxOptions}
						/>
						<ComboboxSetter
							className="insolvency-risk-filter"
							value={filterParams.insolvencyRiskRating}
							setValue={v => updateFilterParams({ insolvencyRiskRating: v })}
							getOptionValue={(value?: string) => value}
							placeholder="Insolvency risk rating"
							label=""
							labelVisible={false}
							searchable
							options={riskScoreComboboxOptions}
						/>
						<MultiComboboxSetter
							className="risk-bucket-filter"
							value={filterParams.riskBucket}
							setValue={value => onRiskBucketSetValue(value)}
							getOptionValue={(value?: string) => value}
							placeholder="Risk Bucket"
							label="Risk Bucket (between)"
							searchable
							options={riskScoreComboboxOptions}
						/>
						<MultiComboboxSetter
							className="group-filter"
							value={filterParams.groupIds}
							setValue={v => updateFilterParams({ groupIds: v })}
							getOptionValue={(value?: string) => value}
							placeholder="Group"
							label="&nbsp;"
							searchable
							initialOptions={getGroupOptions}
							options={AwesomeDebouncePromise(getGroupOptions, 250)}
						/>
					</div>
				</If>
			</div>
			<If condition={store.userPermissions.commonManageOrganisations !== 'NONE'}>
				<Link to="/monitor/customers/headings" className="interstitial-action icon-left icon-material-edit">
					Edit headings
				</Link>
			</If>
			<div className="collection-component">
				{/* Component to fetch and render the List */}
				<RequestWrap
					refreshKey={refreshKey}
					request={() => fetchCustomersDebounced(
						columns,
						search,
						sortParams,
						filterParams,
						page,
						setCount,
					)}
				>
					{(customers: CustomerEntity[]) => (
						<>
							<EntityList
								collection={customers}
								columns={
									columns.map(column => {
										const columnType = CustomerColumnTypes[column.fieldName];
										return {
											displayName: (
												<>
													{column.displayName}
													<If condition={!!CustomerColumnTypes[column.fieldName].tooltip}>
														<Tooltip
															id={`customer-list-header-tooltip-${column.fieldName}`}
															content={
																CustomerColumnTypes[column.fieldName].tooltip
															}
														/>
													</If>
												</>
											),
											columnName: column.fieldName,
											value: columnType.displayFunction,
											sortable: !!columnType.sortColumn,
											onClickValue: columnType.onClickValue,
											sortClicked: !!columnType.sortColumn
												? () => onSortChange(column.fieldName)
												: undefined,
											className: `field-${column.fieldName}`,
										} as IEntityListHeaderProps<CustomerEntity>;
									})
								}
								idColumn="id"
								sortColumn={sortParams.fieldName}
								sortDescending={sortParams.direction === 'DESC'}
								onClickRow={customer => onClickCustomerRow(customer)}
								rowClassName={customer => classNames('no-even-shading', {
									disabled: customer.monitoredStatus !== "MONITORED",
								})}
							/>
							<section className="collection__load">
								<TablePagination
									perPage={CUSTOMERS_PAGE_LENGTH}
									pageNo={page}
									totalRecords={count}
									onPageChange={setPage}
								/>
							</section>
						</>
					)}
				</RequestWrap>
			</div>
		</div>
	);
};
export default CustomerList;

export const CUSTOMERS_PAGE_LENGTH = 10;
