import moment from 'moment/moment';
import * as React from 'react';
import AdverseAlertsTable from './AdverseAlertsTable';
import { atoRecordStatusOptions } from 'Models/Enums';
import { AtoDefaultSummary } from '../../../../../Models/Interfaces/AtoDefaultSummary';
import { LocaleFormatCurrency } from '../../../../../Util/StringUtils';

const AdverseAlertsAtoDefaults = (props: {
	records: AtoDefaultSummary[],
}) => {
	const { records } = props;
	const renderDifferenceAmount = (record: AtoDefaultSummary) => {
		const getClassName = (differenceAmount: number) => {
			if (differenceAmount < 0) {
				return 'ato-difference-less';
			}
			if (differenceAmount > 0) {
				return 'ato-difference-more';
			}
			return 'ato-difference-same';
		};

		return (
			<>
				<span className={getClassName(record.difference)} />
				<span className="align-right">
					{LocaleFormatCurrency(record.difference)}
				</span>
			</>
		);
	};

	return (
		<AdverseAlertsTable
			tableName="ATO defaults"
			className="documents"
			collection={records}
			columns={[
				{
					displayName: 'Date',
					columnName: 'date',
					value: record => moment(record.fileDate).format('Do MMMM YYYY'),
					sortable: false,
					className: 'field-date',
				},
				{
					displayName: 'Type',
					columnName: 'type',
					value: record => atoRecordStatusOptions[record.updateType],
					sortable: false,
					className: 'field-form-code',
				},
				{
					displayName: 'Organisation No.',
					columnName: 'organisationNumber',
					value: record => (record.organisationNumber),
					sortable: false,
					className: 'field-description',
				},
				{
					displayName: 'Status',
					columnName: 'status',
					value: _ => 'Outstanding',
					sortable: false,
					className: 'field-description',
				},
				{
					displayName: 'Amount owing',
					columnName: 'amountOwing',
					value: record => LocaleFormatCurrency(record.outStandingAmount),
					sortable: false,
					className: 'field-description align-right',
				},
				{
					displayName: 'Difference',
					columnName: 'difference',
					value: record => renderDifferenceAmount(record),
					sortable: false,
					className: 'field-description align-right',
				},
			]}
			sortColumn="date"
			sortDescending
			idColumn=""
		/>
	);
};

export default AdverseAlertsAtoDefaults;
