import { AtoRecordEntity, CustomerEntity } from "Models/Entities";
import { atoRecordStatusOptions } from "Models/Enums";
import moment from "moment";
import { Dictionary } from "Util/CustomTypes";

const atoDefaultColumns: {fieldName: string, displayName: string}[] = [
	{ fieldName: 'businessEntityName', displayName: 'Business Entity' },
	{ fieldName: 'businessName', displayName: 'Business Name' },
	{ fieldName: 'debtorId', displayName: 'Debtor ID' },
	{ fieldName: 'abn', displayName: 'ABN' },
	{ fieldName: 'acn', displayName: 'ACN' },
	{ fieldName: 'trusteeACN', displayName: 'Trustee ACN' },
	{ fieldName: 'atoDebt', displayName: 'ATO Debt' },
	{ fieldName: 'atoDebtStatus', displayName: 'ATO Debt Status' },
	{ fieldName: 'atoDebtCreatedDate', displayName: 'ATO Debt Created Date' },
	{ fieldName: 'atoDebtUpdatedDate', displayName: 'ATO Debt Updated Date' },
];

interface AtoDefaultColumnType {
	displayFunction: (customer: CustomerEntity) => string,
}

const AtoDefaultColumnTypes = {
	businessEntityName: {
		displayFunction: (customer: CustomerEntity) => customer.businessEntity.name,
	},
	businessName: {
		displayFunction: (customer: CustomerEntity) => customer.businessName,
	},
	debtorId: {
		displayFunction: (customer: CustomerEntity) => customer.debtorID,
	},
	abn: {
		displayFunction: (customer: CustomerEntity) => customer.abn,
	},
	acn: {
		displayFunction: (customer: CustomerEntity) => customer.acn,
	},
	trusteeACN: {
		displayFunction: (customer: CustomerEntity) => customer.trusteeACN,
	},
	atoDebt: {
		displayFunction: (customer: CustomerEntity) => {
			const monitoredCustomers = customer.monitoredss.map(m => m.monitoreds).filter(m => !!m);
			if (monitoredCustomers.length === 0) {
				return 'N/A';
			}

			const atoDebts = monitoredCustomers.map(m => m.atoDebt).filter(m => !!m);
			if (atoDebts.length === 0) {
				return '';
			}

			return atoDebts?.map(m => m?.amount ?? 0).reduce((a, b) => a + b, 0);
		},
	},
	atoDebtStatus: {
		displayFunction: (customer: CustomerEntity) => {
			const monitoredCustomers = customer.monitoredss.map(m => m.monitoreds).filter(m => !!m);
			if (monitoredCustomers.length === 0) {
				return '';
			}

			const atoRecords: AtoRecordEntity[] = [];
			monitoredCustomers.forEach(m => {
				atoRecords.push(...m.atoRecordss);
			});

			if (atoRecords.length === 0) {
				return '';
			}

			const statuses = atoRecords.map(r => atoRecordStatusOptions[r.recordStatus]).join(", ");
			return statuses;
		},
	},
	atoDebtCreatedDate: {
		displayFunction: (customer: CustomerEntity) => {
			const monitoredCustomers = customer.monitoredss.map(m => m.monitoreds).filter(m => !!m);
			if (monitoredCustomers.length === 0) {
				return '';
			}

			const atoRecords: AtoRecordEntity[] = [];
			monitoredCustomers.forEach(m => {
				atoRecords.push(...m.atoRecordss);
			});

			if (atoRecords.length === 0) {
				return '';
			}

			return atoRecords.map(r => moment(r.recordAdded).format("YYYY-MM-DD HH:mm:ss")).join(", ");
		},
	},
	atoDebtUpdatedDate: {
		displayFunction: (customer: CustomerEntity) => {
			const monitoredCustomers = customer.monitoredss.map(m => m.monitoreds).filter(m => !!m);
			if (monitoredCustomers.length === 0) {
				return '';
			}

			const atoRecords: AtoRecordEntity[] = [];
			monitoredCustomers.forEach(m => {
				atoRecords.push(...m.atoRecordss);
			});

			if (atoRecords.length === 0) {
				return '';
			}

			return atoRecords.map(r => moment(r.recordUpdated).format("YYYY-MM-DD HH:mm:ss")).join(", ");
		},
	},
} as Dictionary<AtoDefaultColumnType>;


export function CreateAtoDefaultsExportDataForCsv(customers: CustomerEntity[]): string[][] {
	const result: string[][] = [];
	// add ato headers
	result.push(atoDefaultColumns.map(c => c.displayName));

	// add ato data
	customers.forEach(customer => {
		// check to avoid empty field on exported field
		// include only those customer which has atoRecord detail
		const monitoredCustomers = customer.monitoredss.map(m => m.monitoreds).filter(m => !!m);
		if (monitoredCustomers.length === 0) {
			return;
		}
		const atoRecords: AtoRecordEntity[] = [];
		monitoredCustomers.forEach(m => {
			atoRecords.push(...m.atoRecordss);
		});

		if (atoRecords.length > 0) {
			result.push(
				atoDefaultColumns.map(column => {
					return AtoDefaultColumnTypes[column.fieldName].displayFunction(customer)?.toString() ?? '';
				})
			)
		}
	});
    
	return result;
}